<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="190px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="服务面积（万亩）">
                        <el-input v-model="form.serviceArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="服务企业（家）">
                        <el-input v-model="form.serviceEnterprise"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="物联网设备（个）">
                        <el-input v-model="form.iotEquipmentCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="企业（家）">
                        <el-input v-model="form.mapEnterpriseCount"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="6">
                    <el-form-item label="种植总面积（万亩）">
                        <el-input v-model="form.jidiBigdataTotalPlantingArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="微型薯快繁中心（万㎡）">
                        <el-input v-model="form.jidiBigdataMicroPotato"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="脱毒组培育中心（万㎡）">
                        <el-input v-model="form.jidiBigdataDetoxificationGroup"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="全日光智能温室（万㎡）">
                        <el-input v-model="form.jidiBigdataFullSunlight"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="微型薯气调存储库（万㎡）">
                        <el-input v-model="form.jidiBigdataStorageOfPotato"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="原种薯气调库（万㎡）">
                        <el-input v-model="form.jidiBigdataOriginalSeedPotato"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <div class="sub-title">基地育种能力</div>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="名称">
                        <el-input v-model="form.abilityBaseAttr1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="数量">
                        <el-input v-model="form.abilityBaseValue1"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位">
                        <el-input v-model="form.abilityBaseUnit1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="名称">
                        <el-input v-model="form.abilityBaseAttr2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="数量">
                        <el-input v-model="form.abilityBaseValue2"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位">
                        <el-input v-model="form.abilityBaseUnit2"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="名称">
                        <el-input v-model="form.abilityBaseAttr3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="数量">
                        <el-input v-model="form.abilityBaseValue3"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位">
                        <el-input v-model="form.abilityBaseUnit3"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="名称">
                        <el-input v-model="form.abilityBaseAttr4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="数量">
                        <el-input v-model="form.abilityBaseValue4"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="单位">
                        <el-input v-model="form.abilityBaseUnit4"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <div class="sub-title">基地实时画面</div>
            </el-row>
            <el-row>
                <el-form-item label="实时画面名称1">
                    <el-input v-model="form.jidiBigVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="实时画面地址1">
                    <el-input style="width: 550px" v-model="form.jidiBigVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="小号画面名称1">
                    <el-input v-model="form.jidiSmallVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="小号画面地址1">
                    <el-input style="width: 550px" v-model="form.jidiSmallVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="小号画面名称2">
                    <el-input v-model="form.jidiSmallVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="小号画面地址2">
                    <el-input style="width: 550px" v-model="form.jidiSmallVedio2Url"></el-input>
                </el-form-item>
            </el-row>
        </el-form>
        <el-row class="ta_c">
            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
        </el-row>

    </div>
</template>

<script>

export default {
    name: '',
    data(){
        return{
            form: {
                mp4: ''
            }
        }
    },
    
    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.datascreen6LoadDataBO({}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        onSaveInfo() {
            this.qa.datascreen6DoSave(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
        beforeAvatarUpload(file) {
            console.log(file.type)
            //const isJPG = file.type === 'image/jpeg';
            //const isLt2M = file.size / 1024 / 1024 < 10;

            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 mp4 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 10MB!');
            // }
            // return  isLt2M;
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.form.mp4 = res.data.url
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
</style>