import { render, staticRenderFns } from "./dataScreen6_potato_mj.vue?vue&type=template&id=746c5ea4&scoped=true&"
import script from "./dataScreen6_potato_mj.vue?vue&type=script&lang=js&"
export * from "./dataScreen6_potato_mj.vue?vue&type=script&lang=js&"
import style0 from "./dataScreen6_potato_mj.vue?vue&type=style&index=0&id=746c5ea4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "746c5ea4",
  null
  
)

export default component.exports